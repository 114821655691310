<template>
  <div class="page-box">
    <base-screen>
      <a-form-model ref="formRef" layout="inline" :model="params" @submit.native.prevent>
        <a-form-model-item label="供应商">
          <a-select v-model="params.partnerShopId" placeholder="请选择" :options="options" style="min-width:200px;"></a-select>
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" @click="handleSearch">查询</a-button>
          <a-button @click="handleReset">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </base-screen>
    <div style="margin-bottom: 18px;">
      <a-button type="primary" v-pms="'add'" :loading="getPostImgLoading" @click="getPostImg">发展供应商</a-button>
    </div>
    <a-table bordered :columns="columns" :dataSource="tableData" :rowKey="(record,index) => record.partnerShopId+index" :loading="tableLoading" :pagination='pagination' @change="handleTableChange">
      <template slot="name" slot-scope="text,record">
        <div class="table-name-box">
          <base-img class="goods-img" :src="record.logo" width="60" height="60" />
          <div>
            <p class="table-name">{{record.name}}</p>
            <p :style="{color:shopAuthStates.find(item=>item.value === record.authState).color}">{{shopAuthStates.find(item=>item.value === record.authState).name}}</p>
          </div>
        </div>
      </template>
      <template slot="loginName" slot-scope="text,record">
        <div v-if="record.loginName">
          <div>{{record.realName}}</div>
          <div>{{record.loginName}}</div>
        </div>
        <div v-else>无</div>
      </template>
      <template slot="linkPhone" slot-scope="text,record">
        <div>{{record.linkMan}}</div>
        <div>{{record.linkPhone}}</div>
      </template>
      <template slot="afterServiceTel" slot-scope="text,record">
        <div>
          <p v-for="(item,index) in record.afterServiceTel" :key="index">{{item}}</p>
        </div>
      </template>
      <div class="table-operations" slot="operation" slot-scope="text, record">
        <a-button type="link" @click='viewGoods(record.partnerShopId)' :disabled="record.productNum<=0">查看商品</a-button>
        <a-popconfirm placement="topRight" title="确定要解除绑定？" ok-text="确定" cancel-text="取消" @confirm="()=>confirmDelete(record)">
          <a-button type="link" v-pms="'unBind'">解除关系</a-button>
        </a-popconfirm>
      </div>
    </a-table>
    <a-modal v-model="postModalShow" :width="375" :closable="false" :title="null" :footer="null">
      <div class="post-img-box">
        <img v-if="postImg" :src="postImg" alt class="post-img" />
        <p v-else>二维码生成失败</p>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { toRefs, reactive, onMounted } from '@vue/composition-api'
import { shopAuthStates } from '@/utils/constants'
import { partner, common, system } from '@/api'
import { SHOPID, SHOPSTAFFID } from '@/constants'
import { getSession } from '@/utils/session'
// import axios from 'axios'
export default {
  name: 'PageSupplier',
  setup (props, { root }) {
    const state = reactive({
      params: {
        type: 0,
        shopId: getSession(SHOPID),
      },
      formRef: null,
      tableLoading: false,
      baseImgUrl: process.env.VUE_APP_IMG,
      options: [],
      tableData: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      postImg: '',
      getPostImgLoading: false,
      postModalShow: false,
    })
    const columns = [
      {
        title: '供应商名称',
        dataIndex: 'name',
        scopedSlots: { customRender: 'name' },
      },
      {
        title: '推荐人',
        dataIndex: 'loginName',
        scopedSlots: { customRender: 'loginName' },
      },
      {
        title: '联系方式',
        dataIndex: 'linkPhone',
        scopedSlots: { customRender: 'linkPhone' },
      },
      {
        title: '售后电话',
        dataIndex: 'afterServiceTel',
        scopedSlots: { customRender: 'afterServiceTel' },
      },
      {
        title: '商品总数量',
        dataIndex: 'productNum',
        customRender: (x) => {
          return x || 0
        },
      },
      {
        title: '采集商品数量',
        dataIndex: 'collectProductNum',
        customRender: (x) => {
          return x || 0
        },
      },
      {
        title: '当前状态',
        dataIndex: 'state',
        customRender: (x) => {
          return '有效'
        },
      },
      {
        title: '生效时间',
        dataIndex: 'createTime',
      },
      {
        title: '操作',
        width: 170,
        dataIndex: 'operation',
        scopedSlots: { customRender: 'operation' },
      },
    ]

    function handleTableChange (pagination) {
      state.pagination = pagination
      getTableList()
    }

    function handleSearch () {
      state.pagination.current = 1
      getTableList()
    }

    function handleReset () {
      state.pagination.current = 1
      state.params = {
        type: 0,
        shopId: getSession(SHOPID),
      }
      handleSearch()
    }
    async function getMySuppler () {
      let { code, msg, data } = await common.getListPartner(0)
      if (code === '00000') {
        state.options = data.map((item) => {
          return {
            label: item.name,
            value: item.shopId,
          }
        })
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function getTableList () {
      state.tableLoading = true
      let { code, data, msg, page } = await partner.getMySupplerPage({
        ...state.params,
        current: state.pagination.current,
        size: state.pagination.pageSize,
      })
      state.tableLoading = false
      if (code === '00000') {
        state.tableData = data.map((item) => {
          if (item.authState === undefined) {
            item.authState = 0
          }
          if (item.afterServiceTel) {
            item.afterServiceTel = item.afterServiceTel.split(',')
          }
          return item
        })
        state.pagination.current = page.pageNo
        state.pagination.pageSize = page.pageSize
        state.pagination.total = page.total
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function confirmDelete (record) {
      let { code, msg } = await partner.unBindRelation({
        deleteFlag: true,
        shopFollowId: record.shopFollowId,
        upShopId: record.partnerShopId,
        downShopId: record.shopId,
      })
      if (code === '00000') {
        root.$message.success('解除绑定成功')
        getTableList()
      } else {
        root.$message.error(msg || '解除绑定失败')
      }
    }
    async function getPostImg () {
      state.getPostImgLoading = true
      const { data, msg, code } = await system.getStaffPoster({
        shopStaffId: getSession(SHOPSTAFFID),
      })
      state.getPostImgLoading = false
      if (code === '00000') {
        showPostImgModal(state.baseImgUrl + data)
      } else {
        root.$message.error(msg || '获取海报图片失败')
      }
      // axios({
      //   url: '/shopFollow/createActivityQrCode',
      //   baseURL: process.env.VUE_APP_API,
      //   headers: { Authorization: getSession(TOKEN) },
      //   params: {
      //     shopId: getSession(SHOPID),
      //     followType: 1
      //   },
      //   responseType: 'arraybuffer'
      // })
      //   .then(response => {
      //     state.getPostImgLoading = false
      //     // 将从后台获取的图片流进行转换
      //     return (
      //       'data:image/png;base64,' +
      //       btoa(
      //         new Uint8Array(response.data).reduce(
      //           (data, byte) => data + String.fromCharCode(byte),
      //           ''
      //         )
      //       )
      //     )
      //   })
      //   .then(data => {
      //     if (data) {
      //       showPostImgModal(data)
      //     }
      //   })
      //   .catch(() => {
      //     root.$message.error('生成二维码失败')
      //   })
    }
    function showPostImgModal (data) {
      state.postImg = data
      state.postModalShow = true
    }
    function viewGoods (id) {
      root.$router.push('/goods/shop_goods_list/' + id)
    }
    onMounted(() => {
      getMySuppler()
      getTableList()
    })
    return {
      shopAuthStates,
      baseUrl: process.env.VUE_APP_IMG,
      columns,
      ...toRefs(state),
      handleReset,
      handleSearch,
      handleTableChange,
      getMySuppler,
      getTableList,
      confirmDelete,
      getPostImg,
      showPostImgModal,
      viewGoods,
    }
  },
}
</script>
<style lang="less" scoped>
::v-deep .ant-modal-body {
  padding: 0;
}
.table-name-box {
  display: flex;
  align-items: center;
  .goods-img {
    margin-right: 8px;
    flex-shrink: 0;
  }
  .table-name {
    margin-bottom: 8px;
  }
}
.post-img-box {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.post-img {
  width: 100%;
  object-fit: contain;
}
</style>
